<!--
 * @Descripttion: 全部目标
 * @version: 1.0.0
 * @Author: xup@imyfone.cn
 * @Date: 2022-07-13 14:49:24
 * @LastEditors: tangjz
 * @LastEditTime: 2023-09-05 18:15:45
-->

<template>
  <el-affix :offset="71">
    <div class="bg-white-line"></div>
    <div class="okr-alltarget-top">
      <div>
        <span v-if="isdep" :class="['cursor-pointer', tabSel === '部门目标' || tabSel === '公司目标' ? 'select-top' : '' ]" @click="changeTab(isCompany ? '公司目标' : '部门目标')">{{isCompany ? '公司目标' : '部门目标'}}</span>
        <span v-if="!isCompany" :class="['cursor-pointer', tabSel === '成员目标' ? 'select-top' : '' ]" @click="changeTab('成员目标')">成员目标</span>
      </div>
      <PickDate choiceModel="more" @confirmSelDate="confirmSelDate" ref="okrCalendar"></PickDate>
    </div>
    <div class="cover-line"></div>
  </el-affix>
  <div class="okr-alltarget-wrapper" v-loading="loading">
    <ul class="okr-target-list" v-if="data.length" v-infinite-scroll="loadMore" infinite-scroll-immediate="false" infinite-scroll-distance="0">
      <li class="okr-target-item" v-for="item in data" :key="item">
        <OkrTemplate :okrData="item" :showRightBtn="false" :showDepartment="true" :cycleId="selDate.selID"><template #footer><span></span></template></OkrTemplate>
      </li>
    </ul>
    <EmptyData v-else image="no-okr-target" description="暂无目标"></EmptyData>
  </div>
</template>

<script>
import { computed, reactive, toRefs, watch } from 'vue'
import OkrTemplate from '@/views/layout/work/okr/myokr/component/OkrTemplate.vue'
import { useRoute, onBeforeRouteLeave } from 'vue-router'
import { useStore } from 'vuex'
import { getOkrDeptAll, getOkrUserAll } from '@/apis/okr.js'

export default {
  name: 'AllTarget',
  components: {
    OkrTemplate
  },
  setup () {
    const route = useRoute()
    const store = useStore()
    const state = reactive({
      tabSel: computed(() => store.state.okr.targetType),
      okrType: 'checking',
      okr: computed(() => store.state.okr),
      data: [],
      okrCalendar: null,
      selDate: {},
      params: {
        limit: 10,
        page: 1,
        cycle_id: undefined,
        type: 1 // 全部 1 进行中 2
      },
      noMore: computed(() => store.state.noMore),
      loading: false,
      isdep: true, // 是否为部门id
      isCompany: computed(() => process.env.VUE_APP_COMPANY_NAME === store.state.okr.allTargetSel)
    })

    // 监听切换部门
    watch(() => route.params.allTargetID, (newValue) => {
      route.params.tabsel && route.params.tabsel === '成员目标' ? store.commit('okr/setTargetType', '成员目标')
        : state.isCompany ? store.commit('okr/setTargetType', '公司目标') : store.commit('okr/setTargetType', '部门目标')
      newValue && getOkrList(state.selDate)
    })

    const getOkrList = (date, type = 'refresh') => {
      if (type !== 'refresh') {
        state.params.page += 1
        store.commit('setLoadMore', true)
        if (state.noMore) return store.commit('setLoadMore', false)
      } else {
        state.data = []
        state.params.page = 1
        // 获取日期
        state.params.cycle_id = Array.isArray(date.selID) ? date.selID : [date.selID]
      }
      state.loading = true
      if (state.tabSel === '部门目标' || state.tabSel === '公司目标') {
        state.params.user_id = undefined
        state.params.dept_id = route.params.allTargetID
        getOkrDeptAll(state.params).then(({ code, data, msg }) => {
          if (code === 0) {
            state.params.page === 1 ? state.data = data.data : state.data.push(...data.data)
            data.data.length < 10 && data.data.length ? store.commit('setNoMore', true) : store.commit('setNoMore', false)
          } else {
            console.log(msg)
          }
        }).finally(() => {
          if (!state.data.length) store.commit('setNoMore', false)
          setTimeout(() => {
            store.commit('setLoadMore', false)
            state.loading = false
          }, 500)
        })
      } else {
        if (route.query.isDep) {
          state.params.dept_id = route.params.allTargetID
          state.params.user_id = undefined
        } else {
          state.params.dept_id = route.params.depId
          state.params.user_id = route.params.allTargetID
        }
        getOkrUserAll(state.params).then(({ code, data, msg }) => {
          if (code === 0) {
            // console.log(data)
            data.data.forEach(i => {
              i.dept.name = i.user.name
            })
            state.params.page === 1 ? state.data = data.data : state.data.push(...data.data)
            data.data.length < 10 && data.data.length ? store.commit('setNoMore', true) : store.commit('setNoMore', false)
          } else {
            console.log(msg)
          }
        }).finally(() => {
          store.commit('setLoadMore', false)
          if (!state.data.length) store.commit('setNoMore', false)
          setTimeout(() => {
            store.commit('setLoadMore', false)
            state.loading = false
          }, 500)
        })
      }
    }

    const loadMore = () => {
      getOkrList(state.selDate, 'nofresh')
    }

    onBeforeRouteLeave(() => {
      store.commit('setNoMore', false)
    })

    // 选择日期
    const confirmSelDate = (date) => {
      if (route.name !== 'allTarget') return
      state.params.type = date.selType
      state.selDate = date
      getOkrList(date)
    }

    // 切换tab
    const changeTab = (value) => {
      store.commit('okr/setTargetType', value)
      getOkrList(state.selDate)
      window.scrollTo(0, 0)
    }

    return {
      ...toRefs(state),
      changeTab,
      confirmSelDate,
      loadMore
    }
  }
}

</script>

<style lang="less" scoped>
// :deep(.el-affix--fixed) {
//   z-index: 2 !important;
// }
.bg-white-line {
  background: #f6f9fa;
}
.okr-alltarget-top {
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  height: 46px;
  padding: 8px 24px;
  background: #FFFFFF;
  border: 1px solid #F1F1F1;
  border-radius: 6px;
  z-index: 2;
  &::before {
    content: '';
    position: absolute;
    top: -9px;
    left: -10px;
    display: inline-block;
    width: 110%;
    height: 8px;
    background: #f6f9fa;
    border: none;
    border: 0px;
    border-radius: 0;
  }
  span {
    display: inline-block;
    font-size: 13px;
    width: 80px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    border-radius: 44px;
    margin-right: 16px;
    border: 1px solid #F1F1F1;
    background: #F6F9FA;
    color: #999;
    font-weight: 400;
    transition: all .3s;
  }
  .select-top {
    border: 1px solid @active-text-color;
    background: #F2F1FE;
    color: @active-text-color;
    font-weight: 700;
  }
  :deep(.selectDate) {
    transform: none;
    .el-icon {
      top: 9px;
    }
  }
}
.okr-target-item {
  margin-top: 8px;
}

:deep(.el-empty) {
  width: auto !important;
  padding: 130px 0;
  margin-bottom: 60px;
  margin-top: 8px;
  border: 1px solid #F1F1F1;
  border-radius: 6px;
  .el-empty__description {
    margin-top: 0;
    font-size: 14px;
    color: #999999;
  }
  .el-empty__bottom {
    margin-top: 0;
    height: 0;
  }
}
</style>
